import React from "react";
import CalendarComponent from "../../components/calendar";


const CalendarPage = () => {
    return (
      <div className="calender-page" >
          {/* <Strategies /> */}
          <CalendarComponent/>
      </div>
    );
  };
  export default CalendarPage;