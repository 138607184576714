import axios from "axios";
import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { getToken, setToken } from "../helpers";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useAuthContext } from "../context/AuthContext";
import { API } from "../constant";
const localizer = momentLocalizer(moment);

// const base_url_server = 'http://localhost:1337/api';
const jwt_token = getToken()
function CalendarComponent() {
    const [eventsByDate, setEventsByDate] = useState({});
    const [loading, setLoading] = useState(true);
    const { user, isLoading, setUser } = useAuthContext();
    useEffect(() => {
        const fetchEventData = async () => {
            try {
                console.log("user", user)
                const response = await axios.get(`${API}/posts?filters[scheduled_by][username][$eq]=${user.username}`, {
                    headers: {
                        'Authorization': `Bearer ${jwt_token}`
                    },
                });

                console.log("jwt", jwt_token)
                console.log('response.data.data', response.data.data);
                let eventsData = [];

                eventsData = response.data.data.map(post => (
                    post.attributes.status === 'published' ?
                        {
                            date: moment(post.attributes.tm_posted).format('YYYY-MM-DD'),
                            content: post.attributes.content,
                            time: moment(post.attributes.tm_posted).format('HH:mm')
                        } : {
                            date: moment(post.attributes.tm_scheduled).format('YYYY-MM-DD'),
                            content: post.attributes.content,
                            time: moment(post.attributes.tm_scheduled).format('HH:mm')
                        }
                ));
                // Group events by date
                const groupedEvents = eventsData.reduce((acc, event) => {
                    const date = event.date;
                    if (!acc[date]) {
                        acc[date] = [];
                    }
                    acc[date].push(event);
                    return acc;
                }, {});


                setEventsByDate(groupedEvents);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching events:", error);
            }
        };

        fetchEventData();
    }, [user]);

    return (
        <div>
            <h1>Event Calendar</h1>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <Calendar
                    localizer={localizer}
                    defaultDate={new Date()}
                    defaultView="month"
                    events={Object.keys(eventsByDate).reduce((acc, date) => {
                        return acc.concat(eventsByDate[date].map(event => ({
                            start: moment(date + 'T' + event.time).toDate(),
                            end: moment(date + 'T' + event.time).add(1, 'hour').toDate(),
                            title: event.content
                        })));
                    }, [])}
                    style={{ height: "100vh" }}
                />
            )}
        </div>
    );
}

export default CalendarComponent;