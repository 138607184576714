import * as React from 'react';
//import CalendarComponent from "./components/calendar";
import SignIn from "./pages/SignIn/SignIn";
import CalendarComponent from './components/calendar';
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import AuthProvider from './components/AuthProvider/AuthProvider';
import AppRoutes from './Routes';
import ResponsiveAppBar from './pages/HomePage/HomePage.js';


export default function App() {

  return (
    <>
      <ResponsiveAppBar/>
        <Routes>
        </Routes>
  
        <AppRoutes />
        {/* </Content>
      </Layout> */}

    </>
  );
}

